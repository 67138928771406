import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

export default function FirstHomeScreen({ setIsProgressFinish }) {
  const [progress, setProgress] = useState(0);
  const controls = useAnimation();
  const [startAnimation, setStartAnimation] = useState(false);
  const [reverseAnimation, setReverseAnimation] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStartAnimation(true);
    }, 2000); // 2000 ms delay to start the animation

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (startAnimation) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 100) {
            return prev + 1;
          } else {
            clearInterval(interval);
            setReverseAnimation(true);
            setTimeout(() => {
              setIsProgressFinish(true);
            }, 1500); 
            return prev;
          }
        });
      }, 20); // 20 ms delay for each increment
    }
  }, [startAnimation]);

  // loading animation
  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      controls.start({ opacity: 1, x: 0 });
    }, 2000); // 3000 ms delay to start the animation

    return () => clearTimeout(animationTimeout);
  }, [controls]);

  const letterAnimation = {
    initial: { y: "20%" },
    animate: {
      y: ["0%", "-20%", "0%"],
      transition: {
        y: {
          duration: 1, // Adjust the duration as needed
          repeat: Infinity, // Repeat infinitely
          repeatType: "reverse", // Reverse animation direction on repeat
        },
      },
    },
  };

  const reverseLetterAnimation = {
    initial: { y: "0%" },
    animate: {
      y: ["0%", "20%", "0%"],
      transition: {
        y: {
          duration: 1, // Adjust the duration as needed
          repeat: Infinity, // Repeat infinitely
          repeatType: "reverse", // Reverse animation direction on repeat
        },
      },
    },
  };

  const containerAnimation = {
    animate: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <div
      className="fixed -left-0 top-0 w-full h-screen  z-500"
      data-component="preloader"
      data-scroll-section=""
    >
      <div className="w-full h-full scale-75 opacity-1" data-content="">
        <motion.span
          initial={{ x: "-200%" }}
          animate={
            reverseAnimation
              ? { x: "200%" }
              : { x: 0, border: "2px solid gray" }
          }
          transition={{
            ease: [0.76, 0, 0.24, 1],
            duration: 2,
          }}
          className="top-0 -m-20 absolute w-full h-full rounded lg:rounded-lg shadow-2xl shadow-white bg-primary"
          data-right-side=""
        >
          <span className="w-full h-full flex rounded lg:rounded-lg"></span>
        </motion.span>
        <motion.span
          initial={{ x: "200%" }}
          animate={
            reverseAnimation
              ? { x: "-200%" }
              : { x: 0, border: "2px solid gray" }
          }
          transition={{
            ease: [0.76, 0, 0.24, 1],
            duration: 2,
          }}
          className="top-0 absolute -m-10 w-full h-full rounded lg:rounded-lg bg-primary"
          data-left-side=""
        >
          <span className="w-full h-full flex rounded lg:rounded-lg"></span>
        </motion.span>
        <motion.span
          initial={{ y: "200%" }}
          animate={
            reverseAnimation
              ? { y: "-200%" }
              : { y: 0, border: "2px solid gray" }
          }
          transition={{
            ease: [0.76, 0, 0.24, 1],
            duration: 2,
          }}
          className="top-0 absolute w-full h-full rounded lg:rounded-lg bg-primary"
          data-left-side=""
        >
          <div className="w-full h-full rounded lg:rounded-lg bg-primary flex flex-col">
            <div className="w-full h-full flex flex-col justify-center items-center flex-grow ">
              <motion.div
                className="text-[15vw] font-bold text-white absolute"
                variants={containerAnimation}
                initial="initial"
                animate={startAnimation ? "animate" : ""}
              >
                {"Welcome".split("").map((letter, index) => (
                  <motion.span
                    key={index}
                    variants={
                      reverseAnimation
                        ? reverseLetterAnimation
                        : letterAnimation
                    }
                    className="inline-block"
                  >
                    {letter}
                  </motion.span>
                ))}
              </motion.div>
            </div>
            <div className="w-full flex justify-end p-10">
              <div className="flex justify-between items-center w-full font-semibold text-4xl">
                <span className="">Loading</span>
                <span className="text-7xl">{progress} %</span>
              </div>
            </div>
          </div>
        </motion.span>
      </div>
    </div>
  );
}
