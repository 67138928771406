import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";

const Wave = () => {
  const wave1Ref = useRef(null);
  const wave2Ref = useRef(null);
  const wave3Ref = useRef(null);
  const wave4Ref = useRef(null);

  useEffect(() => {
    let scrollSpeed = 100;
    let windowScrollY = window.scrollY > 0 ? window.scrollY : 50;
    let isScrolling;

    const updateScrollPosition = () => {
      windowScrollY = 300;

      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        windowScrollY = 100;
      }, 200);
    };

    const animateWaves = () => {
      scrollSpeed += windowScrollY * 0.01;

      if (wave1Ref.current)
        wave1Ref.current.style.backgroundPositionX = scrollSpeed * 3 + "px";
      if (wave2Ref.current)
        wave2Ref.current.style.backgroundPositionX = scrollSpeed * -2 + "px";
      if (wave3Ref.current)
        wave3Ref.current.style.backgroundPositionX = scrollSpeed * 1 + "px";
      if (wave4Ref.current)
        wave4Ref.current.style.backgroundPositionX = scrollSpeed * -1 + "px";

      requestAnimationFrame(animateWaves);
    };

    window.addEventListener("scroll", updateScrollPosition);
    animateWaves();

    return () => {
      window.removeEventListener("scroll", updateScrollPosition);
      window.cancelAnimationFrame(animateWaves);
    };
  }, []);

  return (
    <div className="w-full  rounded-t-lg relative">
      <div className=" flex overflow-hidden whitespace-nowrap ">
        <div className="flex justify-center  items-end h-[30vh]  w-screen  ">
          <div
            ref={wave1Ref}
            className="wave1 h-40 w-full absolute "
            style={{
              zIndex: 10000,
              background: `url('/wave/wave2.png')`,
              backgroundSize: "contain",
              backgroundPosition: "bottom",
              animation: "wave 10s linear infinite",
            }}
          ></div>
          <div
            ref={wave2Ref}
            className="wave2 h-44 w-full absolute  opacity-60"
            style={{
              zIndex: 9999,
              background: `url('/wave/wave2.png')`,
              backgroundSize: "contain",
              backgroundPosition: "bottom",
              animation: "wave 10s linear infinite",
            }}
          ></div>
          <div
            ref={wave3Ref}
            className="wave3 h-48 w-full absolute  opacity-60"
            style={{
              zIndex: 9998,
              background: `url('/wave/wave2.png')`,
              backgroundSize: "contain",
              backgroundPosition: "bottom",
              animation: "wave 10s linear infinite",
            }}
          ></div>
          <div
            ref={wave4Ref}
            className="wave4 h-56 w-full absolute  opacity-60"
            style={{
              zIndex: 9997,
              background: `url('/wave/wave2.png')`,
              backgroundSize: "contain",
              backgroundPosition: "bottom",
              animation: "wave 10s linear infinite",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Wave;
