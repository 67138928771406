import React from "react";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <div
      data-scroll-section=""
      data-scroll=""
      data-scroll-speed="-0.3"

      className="w-full h-[70vh] bg-primary pt-1 z-[9998]  flex justify-center "
    >
      <div className="textStricture mt-32  w-[95%] md:w-[80%]">
        {["Hello, ", "I am Ayush Gurung ", "presentations "].map(
          (text, index) => (
            <div key={index} className="masker bg-reg">
              <div className="w-fit flex items-end overflow-hidden">
                {index === 1 && (
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: "8vw" }}
                    transition={{
                      ease: [0.76, 0, 0.24, 1],
                      duration: 1.5,
                    }}
                    className="mr-5 w-[8vw] rounded-md h-[5.5vw] bg-secondary relative top-[0vw]"
                  ></motion.div>
                )}
                <h1 className=" -md-[1vw] uppercase text-[5vw]  font-bold tracking-tighter">
                  {text}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Banner;
// return (
//     <div
//     data-scroll
//     data-scroll-section
//     data-scroll-speed="-0.4"

//     className="  w-full  justify-between flex-col -z-50  ">
//       <div className=" text-white flex justify-center items-center  mt-20">
//         <div className=" banner w-[95%] md:w-[80%] cursor-default ">
//           <div className="text-6xl font-bold"> Hello, </div>
//           <div className="text-[9rem] font-bold"> I am Ayush Gurung </div>
//           <div className="text-7xl font-bold flex">
//             <Swiper
//               spaceBetween={0}
//               //   direction={"vertical"}
//               centeredSlides={true}
//               autoplay={{
//                 delay: 1000,
//                 disableOnInteraction: false,
//               }}
//               modules={[Autoplay]}
//               className="mySwiper"
//             >
//               <SwiperSlide>Front</SwiperSlide>
//               <SwiperSlide>Back</SwiperSlide>
//             </Swiper>
//             - End Developer{" "}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
