import "./App.css";
import MouseTracker from "./component/mouserTrack/tracker.jsx";
import Index from "./pages/index.jsx";
import Home from "./pages/Home.jsx";

function App() {
  return (
    <>
      <MouseTracker />
      {/* <Index /> */}
      <Home />
    </>
  );
}

export default App;
