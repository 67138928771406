import React from "react";

const About = () => {
  return (
    <>
      <div
        // data-scroll
        // data-scroll-section
        data-scroll-speed="0.15"
        className="w-full py-20 h-screen bg-[#CDEA68] rounded-t-lg z-[9999] flex justify-center "
      >
        <div className="w-[95%] md:w-[80%]">
          <h1 className="text-[4vw] leading-[4.5vw] tracking-tight ">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit
            repellendus incidunt placeat accusamus, nemo soluta temporibus
            molestias tenetur obcaecati.
          </h1>

          <div className="flex gap-5 w-full border-t-[1px] pt-10 border-[#f0f9cf] mt-20">
            <div className="w-1/2">
              <h1 className="capitalize text-7xl">our approach</h1>
              <div className="flex w-[11vw] gap-10 items-center px-10 py-6 bg-primary mt-10 rounded-full text-white">
                Read More
                <div className="h-2 w-2 bg-white rounded-full"></div>
              </div>
            </div>

            <div className="w-1/2 h-[20vw] rounded-3xl bg-primary"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
