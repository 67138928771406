import { motion, AnimatePresence } from "framer-motion";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";

const Header = () => {
  return (
    <div className="header z-[999999] text-white flex justify-center items-center  pt-3  sticky top-0 bg-transparent bg-opacity-70 backdrop-filter backdrop-blur-lg">
      <AnimatePresence>
        <motion.div
          initial={{
            y: -200,
          }}
          animate={{
            y: 0,
          }}
          exit={{
            y: -200,
          }}
          transition={{
            duration: 1,
            ease: "easeInOut",
          }}
          className="w-[95%] md:w-[80%] border-b"
        >
          <header className="flex justify-between items-center p-2  z-50 ">
            <div>
              <h1 className="text-3xl font-extrabold">Ayush</h1>
              <h1 className="text-xl font-extrabold">Gurung</h1>
            </div>
            <div>
              <ul className="flex justify-between items-center capitalize text-2xl font-extrabold gap-4">
                <li className="group border p-1 hover:bg-white rounded-md h-10 aspect-square flex flex-col justify-center items-center gap ease-out duration-200 transform hover:scale-125">
                  <div className="relative h-full w-full bg-gradient-to-r rounded-lg flex justify-center items-center group-hover:bg-white">
                    <div className="h-4 w-4 border-2 border-white group-hover:border-black group-hover:bg-white rounded-full"></div>
                    <div className="absolute top-0 right-0 h-1 w-1 bg-white group-hover:bg-black rounded-full"></div>
                  </div>
                </li>

                <li className="group border hover:bg-white rounded-md h-10 aspect-square flex justify-center items-center ease-out duration-200 transform hover:scale-125">
                  <FaFacebookF className="group-hover:text-black group-hover:bg-white" />
                </li>

                <li className="group border hover:bg-white rounded-md h-10 aspect-square flex justify-center items-center ease-out duration-200 transform hover:scale-125">
                  <FaLinkedinIn className="group-hover:text-black group-hover:bg-white" />
                </li>

                <li className="group border hover:bg-white rounded-md h-10 aspect-square flex justify-center items-center ease-out duration-200 transform hover:scale-125">
                  <FaXTwitter className="group-hover:text-black group-hover:bg-white" />
                </li>
              </ul>
            </div>
            <div>
              <button className="border-2 text-white px-3 py-1 rounded-md text-xl font-semibold ease-out duration-200 transform hover:scale-110 hover:bg-white hover:text-black">
                Resume
              </button>
            </div>
          </header>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
export default Header;
