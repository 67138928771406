import React, { useState, useEffect } from "react";
import Header from "../component/home/include/header";
import Banner from "../component/final/Banner";
import Wave from "../component/final/Wave";
import About from "../component/final/About";
import LocomotiveScroll from "locomotive-scroll";
import FirstHomeScreen from "./firstHomeScreen";

const Home = () => {
  const [IsProgressFinish, setIsProgressFinish] = useState(false);

  useEffect(() => {
    if (IsProgressFinish) {
      const scroll = new LocomotiveScroll({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
      });

      return () => scroll.destroy();
    }
  }, [IsProgressFinish]);

  return (
    <div className="w-full min-h-screen text-white bg-primary">
      <FirstHomeScreen setIsProgressFinish={setIsProgressFinish} />
      {IsProgressFinish && (
        <>
          <Header />
          <Banner />
          <Wave />
          <About />
        </>
      )}
    </div>
  );
};

export default Home;
